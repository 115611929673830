import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ErrorRouteData } from './error-component.model';
import { SharelockCommonModule } from '../../modules/sharelock-common.module';

@Component({
  selector: 'shrl-error-component',
  imports: [SharelockCommonModule],
  templateUrl: './error.component.html',
  styleUrl: './error.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorComponent {
  readonly #route = inject(ActivatedRoute);

  routeData$ = this.#route.data as Observable<ErrorRouteData>;
}
