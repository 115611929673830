import { authGuard } from '../../core/guards/auth.guard';
import {
  ENavigationRoute,
  ENavigationUnitComponentSelection,
} from '../../core/model/navigation.model';
import { EUserRole } from '../../core/model/user.model';
import { IShrlRoutes } from '../../shrl.routes';
import { adminGuard } from './guards/admin.guard';
import { AdminBreadcrumbResolver } from './services/admin-api-breadcrumb.service';

export const DASHBOARD_ROUTES: IShrlRoutes = [
  {
    path: ENavigationRoute.DASHBOARD,
    data: {
      label: 'DASHBOARD.DASHBOARD.TITLE',
      breadcrumbLabel: 'DASHBOARD.DASHBOARD.TITLE',
      visibleForUsers: [EUserRole.ROLE_ADMIN],
      componentSelection: [
        ENavigationUnitComponentSelection.TOPBAR,
        ENavigationUnitComponentSelection.SIDENAV,
      ],
    },
    canActivate: [authGuard, adminGuard],
    loadComponent: () =>
      import('./pages/dashboard/dashboard.component').then(
        (x) => x.DashboardComponent
      ),
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: ENavigationRoute.USERS,
      },
      {
        path: ENavigationRoute.USERS,
        data: {
          label: 'DASHBOARD.USERS.TITLE',
          breadcrumbLabel: 'DASHBOARD.USERS.TITLE',
          visibleForUsers: [EUserRole.ROLE_ADMIN],
          componentSelection: [
            ENavigationUnitComponentSelection.TOPBAR,
            ENavigationUnitComponentSelection.SIDENAV,
          ],
        },
        children: [
          {
            path: '',
            loadComponent: () =>
              import('./pages/users-list/users-list.component').then(
                (x) => x.UsersListComponent
              ),
            data: {
              visibleForUsers: [EUserRole.ROLE_ADMIN],
            },
          },
          {
            path: ':id',
            loadComponent: () =>
              import(
                './components/user/user-container/user-container.component'
              ).then((x) => x.UserContainerComponent),
            resolve: { breadcrumbLabel: AdminBreadcrumbResolver },
            data: {
              breadcrumbLabel: 'DASHBOARD.USER.TITLE',
            },
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: ENavigationRoute.USER_INFO,
              },
              {
                path: ENavigationRoute.USER_INFO,
                loadComponent: () =>
                  import('./pages/user-info/user-info.component').then(
                    (x) => x.UserInfoComponent
                  ),
                data: {
                  label: 'DASHBOARD.USER_INFO.TITLE',
                  visibleForUsers: [EUserRole.ROLE_ADMIN],
                },
              },
              {
                path: ENavigationRoute.APARTMENTS,
                loadComponent: () =>
                  import(
                    './pages/user-apartments/user-apartments.component'
                  ).then((x) => x.UserApartmentsComponent),
                data: {
                  label: 'DASHBOARD.APARTMENTS.TITLE',
                  visibleForUsers: [EUserRole.ROLE_ADMIN],
                },
              },
              {
                path: ENavigationRoute.RESERVATIONS,
                loadComponent: () =>
                  import(
                    './pages/user-reservations/user-reservations.component'
                  ).then((x) => x.UserReservationsComponent),
                data: {
                  label: 'DASHBOARD.RESERVATIONS.TITLE',
                  visibleForUsers: [EUserRole.ROLE_ADMIN],
                },
              },
              {
                path: ENavigationRoute.ORDERS,
                loadComponent: () =>
                  import('./pages/user-orders/user-orders.component').then(
                    (x) => x.UserOrdersComponent
                  ),
                data: {
                  label: 'DASHBOARD.ORDERS.TITLE',
                  visibleForUsers: [EUserRole.ROLE_ADMIN],
                },
              },
              {
                path: ENavigationRoute.DISCOUNTS,
                loadComponent: () =>
                  import(
                    './pages/user-discounts/user-discounts.component'
                  ).then((x) => x.UserDiscountsComponent),
                data: {
                  label: 'DASHBOARD.DISCOUNTS.TITLE',
                  visibleForUsers: [EUserRole.ROLE_ADMIN],
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
